import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ReactGA from 'react-ga';
// import NotesTable from './components/NotesTable';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format, sub } from 'date-fns';
import NotesGraph from './components/NotesGraph';
import StatesPie from './components/StatesPie';
import StatusPie from './components/StatusPie';
import CountRequest from './components/CountRequest';
import RequestGraph from './components/RequestGraph';

registerLocale('ptBR', ptBR);

const Home = () => {
  const [dateRange, setDateRange] = useState([
    sub(new Date(), { weeks: 1 }),
    new Date()
  ]);
  const [startDate, endDate] = dateRange;
  const [selected, setSelect] = useState('ALL');
  const [inicialDate, setInicialDate] = useState(
    format(sub(new Date(), { weeks: 1 }), 'dd/MM/yyyy')
  );
  const [finalDate, setFinalDate] = useState(format(new Date(), 'dd/MM/yyyy'));
  const { name, owner } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const onChange = (date) => {
    setDateRange(date);
    if (date[0] && date[1]) {
      setInicialDate(format(new Date(date[0]), 'dd/MM/yyyy'));
      setFinalDate(format(new Date(date[1]), 'dd/MM/yyyy'));
    }
  };

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '30px' }}>
        <Col xs={12} md={12} xl={7}>
          <h3 className="page-title">Bem vindo, {name}</h3>
        </Col>
        <Col xs={12} md={12} xl={2} className="p-0 text-center">
          <div className="form">
            <div className="form__form-group m-0">
              <div className="form__form-group-field">
                <select
                  className="select-form custom-select"
                  onChange={(e) => setSelect(e.target.value)}
                  value={selected}
                >
                  <option value="ALL">Todos</option>
                  <option value="12">Acre</option>
                  <option value="27">Alagoas</option>
                  <option value="16">Amapá</option>
                  <option value="13">Amazonas</option>
                  <option value="29">Bahia</option>
                  <option value="23">Ceará</option>
                  <option value="53">Distrito Federal</option>
                  <option value="32">Espírito Santo</option>
                  <option value="52">Goiás</option>
                  <option value="21">Maranhão</option>
                  <option value="51">Mato Grosso</option>
                  <option value="50">Mato Grosso do Sul</option>
                  <option value="31">Minas Gerais</option>
                  <option value="15">Pará</option>
                  <option value="25">Paraíba</option>
                  <option value="41">Paraná</option>
                  <option value="26">Pernambuco</option>
                  <option value="22">Piauí</option>
                  <option value="33">Rio de Janeiro</option>
                  <option value="24">Rio Grande do Norte</option>
                  <option value="43">Rio Grande do Sul</option>
                  <option value="11">Rondônia</option>
                  <option value="14">Roraima</option>
                  <option value="42">Santa Catarina</option>
                  <option value="35">São Paulo</option>
                  <option value="28">Sergipe</option>
                  <option value="17">Tocantins</option>
                </select>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={12} xl={3} className="p-0 text-center">
          <DatePicker
            selectsRange
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              onChange(update);
            }}
            dateFormat="dd/MM/yyyy"
            locale="ptBR"
            maxDate={new Date()}
          />
        </Col>
      </Row>
      {![
        '1f054bcb-7500-44d4-89e5-6a6c5a4271a7',
        '689acb74-5a1e-4dc0-a410-fe1966034ebd'
      ].includes(owner) && (
        <Row>
          <CountRequest />
        </Row>
      )}
      {![
        '1f054bcb-7500-44d4-89e5-6a6c5a4271a7',
        '689acb74-5a1e-4dc0-a410-fe1966034ebd',
        '739510c6-5155-48f4-a2a2-ebb9041e0f80'
      ].includes(owner) && (
        <Row>
          <Col md={12}>
            <RequestGraph inicialDate={inicialDate} finalDate={finalDate} />
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <NotesGraph
            inicialDate={inicialDate}
            finalDate={finalDate}
            state={selected}
            url="checkOwnerRequestByMonth"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <StatusPie
            inicialDate={inicialDate}
            finalDate={finalDate}
            state={selected}
            url="getCountNotasAllStatus"
          />
        </Col>
        <Col md={6}>
          <StatesPie
            inicialDate={inicialDate}
            finalDate={finalDate}
            state={selected}
            url="getCountNotasAllState"
          />
        </Col>
      </Row>
      {/* <Row>
        <Col md={12}>
          <NotesTable />
        </Col>
      </Row> */}
    </Container>
  );
};

export default Home;
