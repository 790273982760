/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ContentCopy from 'mdi-react/ContentCopyIcon';
import {
  useHistory,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { getToken } from '../../../services/auth';
import Modal from '../../../shared/Modal';

const PIXPage = () => {
  const { owner } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const location = useLocation();
  const [pixCode, setPixCode] = useState('');
  const [transaction, setTransaction] = useState('');
  const [statusTransation, setStatusTransation] = useState('');
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const backToHome = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    if (location.state) {
      const { emv, transactionId } = location.state;
      setTransaction(transactionId);
      setPixCode(emv);
    }
  }, [location.state]);

  useEffect(() => {
    const handleTopRequest = () => {
      const url = `${process.env.REACT_APP_WAS_KEY}/wallet/pix/status/${transaction}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': owner
          }
        })
        .then((res) => {
          if (res.data.status) {
            switch (res.data.status) {
              case 'WAITING':
                break;
              case 'CONFIRMED':
                setStatusTransation(res.data.status);
                setOpen(true);
                break;
              case 'CANCELED':
              case 'ERROR':
                backToHome();
                break;
              case 'EXPIRED':
                setStatusTransation(res.data.status);
                setOpen(true);
                break;
              default:
                break;
            }
          }
        });
    };

    if (transaction) handleTopRequest();

    const intervalId = setInterval(handleTopRequest, 10000);
    return () => clearInterval(intervalId);
  }, [owner, transaction, backToHome]);

  const handleCopy = () => {
    navigator.clipboard.writeText(pixCode);
  };

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '10px' }}>
        <Col xs={12} md={12} xl={10}>
          <h3 className="page-title" style={{ textTransform: 'none' }}>
            Realize o pagamento do PIX
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <p className="mb-4 text-info-custom">
                Escaneie o QR Code abaixo no banco de sua preferência ou utilize
                o método de copiar o código:
              </p>
              <div className="text-center my-4">
                <QRCode value={pixCode} size={256} />
              </div>
              <Row className="w-100">
                <Col xl={3} className="p-0" />
                <Col xl={6} className="pl-3">
                  <p className="text-center text-primary-custom">
                    Clique na caixa abaixo para copiar o código e utilize o Pix
                    Copia e Cola no aplicativo que você irá realizar o
                    pagamento:
                  </p>
                  <div className="copy-field mb-3 mt-3" onClick={handleCopy}>
                    {pixCode.substring(0, 34)}...
                    <ContentCopy className="ml-3" />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 p-0">
                <Col xl={3} className="p-0" />
                <Col xl={6} className="pl-3">
                  <p className="text-center text-secondary-custom">
                    O código QR gerado para pagamento via PIX tem um tempo de
                    expiração de 30 minutos. Por favor, finalize a sua compra
                    dentro deste período para garantir que o pagamento seja
                    realizado com sucesso. Caso o tempo expire, você precisará
                    gerar um novo código QR.
                  </p>
                  <p className="text-center text-secondary-custom">
                    Após a realização do pagamento, a confirmação é imediata.
                    Verifique a tela de confirmação do seu banco para garantir
                    que a transação foi concluída com sucesso. Seu saldo será
                    atualizado assim que o pagamento for confirmado.
                  </p>
                </Col>
                <Col xl={3} />
              </Row>
              {open ? (
                <Modal
                  color={
                    statusTransation === 'CONFIRMED' ? 'primary' : 'danger'
                  }
                  title={
                    statusTransation === 'CONFIRMED' ? 'Sucesso!' : 'Falha!'
                  }
                  colored
                  message={
                    statusTransation === 'CONFIRMED'
                      ? 'Seu pagamento foi aprovado e dentro de instantes o crédito será adicionado ao seu saldo.'
                      : 'O pagamento foi expirado, por favor realize toda operação novamente'
                  }
                  isOpened={open}
                  toggleFunction={backToHome}
                />
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PIXPage;
