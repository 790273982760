/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col, Row } from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { getToken } from '../../../services/auth';
import {
  colorsPalette2,
  normalizeName,
  ShoppingCode
} from '../../../shared/utils/utils';

const logo = `${process.env.PUBLIC_URL}/img/pie-chart.png`;

const style = () => {
  const left = { left: 0 };
  return {
    ...left,
    width: 150,
    lineHeight: '24px',
    position: 'absolute'
  };
};

const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777'
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {payload.map((entry) => (
      <li key={entry.id}>
        <span style={{ backgroundColor: entry.color }} />
        {entry.value}
      </li>
    ))}
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};

const StatesPie = ({ inicialDate, finalDate, url, state, shopping }) => {
  const [refresh, setRefresh] = useState(false);
  const [notes, setNotes] = useState([]);
  const [coordinates, setCoordinate] = useState({ x: 0, y: 0 });

  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinate({ x: e.tooltipPosition.x, y: e.tooltipPosition.y });
    }
  };

  const formatData = (items, urlD) => {
    const aux = [];
    let i = 0
    for (const [k, v] of Object.entries(items)) {
      aux.push({
        id: k + i,
        name:
          urlD === 'getCountNotasAllStateShopping'
            ? normalizeName(ShoppingCode[k])
            : k,
        value: v
      });
      i += 1;
    }
    aux.sort((a, b) => b.value - a.value);
    aux.forEach((data, j) => {
      data.fill = colorsPalette2().reverse()[j];
    });
    return aux;
  };

  const getCountNotes = useCallback(() => {
    setRefresh(true);
    setNotes([]);
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/${url}`;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        params: { inicialDate, finalDate, state, shopping },
        paramsSerializer(params) {
          return qs.stringify(params, { encode: false });
        }
      })
      .then((res) => {
        if (res.data && Object.keys(res.data).length > 0)
          setNotes(formatData(res.data, url));
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setRefresh(false));
  }, [inicialDate, finalDate, url, state, shopping]);

  useEffect(() => {
    const ac = new AbortController();
    getCountNotes();
    return () => ac.abort();
  }, [getCountNotes]);

  return (
    <Card>
      <CardBody className="panel__body dashboard__health-chart-card">
        {refresh ? (
          <div className="panel__refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div className="panel__btns">
          <button
            className="panel__btn"
            aria-label="panel__btn"
            type="button"
            onClick={getCountNotes}
          >
            <AutorenewIcon />
          </button>
        </div>
        <div className="panel__title">
          <h5 className="bold-text text-left">
            {url === 'getCountNotasAllStateShopping' ? 'Shoppings' : 'Estados'}{' '}
            por nota
          </h5>
        </div>
        <div
          style={{
            height: '450px'
          }}
        >
          {notes && notes.length > 0 ? (
            <ResponsiveContainer
              className="dashboard__chart-pie dashboard__chart-pie--commerce"
              height={400}
            >
              <PieChart className="dashboard__chart-pie-container">
                <Tooltip position={coordinates} {...ligthTheme} />
                <Pie
                  data={notes}
                  dataKey="value"
                  cy={180}
                  label
                  isAnimationActive={false}
                  startAngle={90}
                  paddingAngle={2}
                  endAngle={-450}
                  innerRadius={130}
                  outerRadius={160}
                  onMouseMove={onMouseMove}
                />
                <Legend
                  layout="vertical"
                  verticalAlign="bottom"
                  wrapperStyle={style()}
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : !refresh && (
            <Row className="h-100 align-content-center">
              <Col md={12} className="text-center">
                <img src={logo} alt="logo" style={{ width: '240px' }} />
                <p className="text-content m-0 mt-4">
                  Não foi encontrado nenhum dado nesse periodo.
                </p>
              </Col>
            </Row>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

StatesPie.propTypes = {
  finalDate: PropTypes.string.isRequired,
  inicialDate: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  state: PropTypes.string,
  shopping: PropTypes.string
};

StatesPie.defaultProps = {
  state: 'ALL',
  shopping: 'ALL'
};

export default StatesPie;
