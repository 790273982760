import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Row } from 'reactstrap';
import { getToken } from '../../../services/auth';
import { brMoneyMask } from '../../../shared/utils/utils';

const BalanceBadge = () => {
  const { owner } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const history = useHistory();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const handleTopRequest = () => {
      const url = `${process.env.REACT_APP_WAS_KEY}/wallet/balance`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': owner
          }
        })
        .then((res) => {
          setBalance(res.data.balance);
        });
    };

    handleTopRequest();

    const intervalId = setInterval(handleTopRequest, 5000);
    return () => clearInterval(intervalId);
  }, [owner]);

  const gotToRecharge = () => {
    history.push('/paymentInformation');
  };

  return (
    <Row>
      <Col xl={12}>
        <div className="balance-badge d-flex align-items-center">
          <div className="wallet-icon">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.5 9H14C12.3431 9 11 10.3431 11 12C11 13.6569 12.3431 15 14 15H21.5M14 
            12V12.01M4.61111 5H19.3889C20.5548 5 21.5 6.04467 21.5 7.33333V16.6667C21.5 
            17.9553 20.5548 19 19.3889 19H4.61111C3.44518 19 2.5 17.9553 2.5 
            16.6667V7.33333C2.5 6.04467 3.44518 5 4.61111 5Z"
                stroke="#E8EAF6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span className="balance-amount">R$ {brMoneyMask(balance)}</span>
          <button
            className="add-button btn btn-success"
            onClick={gotToRecharge}
            type="button"
          >
            +
          </button>
        </div>
      </Col>
    </Row>
  );
};

export default BalanceBadge;
