/* eslint-disable */

let loading = false;

const LoadingService = {
    getLoading: function () {
        return loading
    },

    setLoading: function (status) {
        loading = status;
    }
};

export default LoadingService;