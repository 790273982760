const invoices = [
    {
        id: "00001",
        services: [{
            description: "Serviço Leitura de Nota Fiscal em até 100 mil validações",
            validations: "100.000",
            value: "R$ 5.000,00"
        }]
    },
    {
        id: "00002",
        services: [{
            description: "Serviço Leitura de Nota Fiscal em até 1 milhão e meio de validações",
            validations: "1.500.000",
            value: "R$ 30.000,00"
        }]
    }
]

export default invoices;