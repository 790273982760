import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Legend,
  Line
} from 'recharts';
import axios from 'axios';
import qs from 'qs';
import LoadingIcon from 'mdi-react/LoadingIcon';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { getToken } from '../../../services/auth';

// const data = [
//   { date: '18/07 00:00', pictures: 200, recaptcha2: 4000, recaptcha3: 200 },
//   { date: '18/07 08:00', pictures: 400, recaptcha2: 8000, recaptcha3: 400 },
//   { date: '19/07 16:00', pictures: 450, recaptcha2: 8200, recaptcha3: 600 },
//   { date: '19/07 00:00', pictures: 400, recaptcha2: 8000, recaptcha3: 400 }
//   // Adicione mais dados conforme necessário
// ];

const RequestGraph = ({ inicialDate, finalDate }) => {
  const date = new Date();
  const today = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  const [data, setData] = useState([{ date: today, count: 0 }]);
  const client = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState('H');
  const formatData = useCallback(
    (res) => {
      const aux = [];
      if (res.length > 0)
        res.forEach((e) => {
          aux.push({
            date: e.day
              ? e.day.split('-').reverse().join('/')
              : `${e.hour.split(' ')[0].split('-').reverse().join('/')} ${
                  e.hour.split(' ')[1]
                }`,
            total: e.count
          });
        });
      else aux.push({ date: today, count: 0 });
      return aux;
    },
    [today]
  );

  const getGraphData = useCallback(() => {
    setRefresh(true);
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/wallet/request/graphic`;
    const startDate = inicialDate.split('/').reverse().join('-');
    const endDate = finalDate.split('/').reverse().join('-');
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'x-api-key': client.owner
        },
        params: { startDate, endDate, type },
        paramsSerializer(params) {
          return qs.stringify(params, { encode: false });
        }
      })
      .then((res) => {
        setData(formatData(res.data));
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setRefresh(false));
  }, [client.owner, inicialDate, finalDate, type, formatData]);

  useEffect(() => {
    const ac = new AbortController();
    getGraphData();
    return () => ac.abort();
  }, [getGraphData]);

  return (
    <Card>
      <CardBody className="panel__body">
        {refresh ? (
          <div className="panel__refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div className="panel__btns">
          <button
            className="panel__btn"
            aria-label="panel__btn"
            type="button"
            onClick={getGraphData}
          >
            <AutorenewIcon />
          </button>
        </div>
        <Row>
          <Col xl={10}>
            <div className="panel__title">
              <h5 className="bold-text">
                Gráfico de Processamento dos serviços
              </h5>
            </div>
          </Col>
          <Col xl={1} className="p-0">
            <div className="form">
              <div className="form__form-group m-0">
                <div className="form__form-group-field">
                  <select
                    className="select-form custom-select"
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                  >
                    <option value="D">por Dia</option>
                    <option value="H">por Hora</option>
                  </select>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="total"
              stroke="#2A2E4F"
              strokeWidth={2}
              name="Total"
            />
            {/* <Line
              type="monotone"
              dataKey="recaptcha2"
              stroke="#82ca9d"
              strokeWidth={2}
              name="reCAPTCHA 2"
            />
            <Line
              type="monotone"
              dataKey="recaptcha3"
              stroke="#8884d8"
              strokeWidth={2}
              name="reCAPTCHA 3"
            /> */}
          </LineChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  );
};

RequestGraph.propTypes = {
  finalDate: PropTypes.string.isRequired,
  inicialDate: PropTypes.string.isRequired
};

export default RequestGraph;
