import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import BalanceBadge from './BalanceBadge';
import { getToken } from '../../../services/auth';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const [type, setType] = useState('');

  const checkToken = () => {
    const decodedToken = jwtDecode(getToken());
    if (decodedToken.sub.chargeType) {
      setType(decodedToken.sub.chargeType);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link className="topbar__logo" to="/home" />
        </div>
        <div className="topbar__right">
          {type === 'CREDIT' && <BalanceBadge />}
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired
};

export default Topbar;
