import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ptBR from 'date-fns/locale/pt-BR';
import { format, sub } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import HistoricTable from './components/HistoricTable';

registerLocale('ptBR', ptBR);

const HistoricPayments = () => {
  const [dateRange, setDateRange] = useState([
    sub(new Date(), { weeks: 1 }),
    new Date()
  ]);
  const [startDate, endDate] = dateRange;
  const [inicialDate, setInicialDate] = useState(
    format(sub(new Date(), { weeks: 1 }), 'dd/MM/yyyy')
  );
  const [finalDate, setFinalDate] = useState(format(new Date(), 'dd/MM/yyyy'));

  const onChange = (date) => {
    setDateRange(date);
    if (date[0] && date[1]) {
      setInicialDate(format(new Date(date[0]), 'dd/MM/yyyy'));
      setFinalDate(format(new Date(date[1]), 'dd/MM/yyyy'));
    }
  };

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '30px' }}>
        <Col xl={8} className="pl-0">
          <h3 className="page-title" style={{ textTransform: 'none' }}>
            Historico de Pagamentos
          </h3>
        </Col>
        <Col xs={12} md={12} xl={4} className="p-0 text-right">
          <DatePicker
            selectsRange
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              onChange(update);
            }}
            dateFormat="dd/MM/yyyy"
            locale="ptBR"
            maxDate={new Date()}
          />
        </Col>
      </Row>
      <Row>
        <HistoricTable inicialDate={inicialDate} finalDate={finalDate} />
      </Row>
    </Container>
  );
};

export default HistoricPayments;
