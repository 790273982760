/* eslint-disable */
import React from 'react';
import { Row } from 'reactstrap';
import { RedocStandalone } from 'redoc';

const Documentation = () => {
  let docs = require('./components/openAPI.json');
  return (
    <Row style={{ marginTop: '-30px', marginRight: '0px' }}>
      <RedocStandalone
        spec={docs}
        options={{
          nativeScrollbars: false,
          theme: {
            colors: { primary: { main: '#007bff' } },
            sidebar: {
              backgroundColor: '#CCD1D7',
              width:"250px"
            },
            typography: {
              fontFamily: 'Inter',
              headings: {
                fontFamily: 'Inter'
              }
            }
          }
        }}
      />
    </Row>
  );
};

export default Documentation;
