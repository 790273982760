import React from 'react';
import { Card, CardBody, Progress } from 'reactstrap';
import PropTypes from 'prop-types';

const CountRequestType = ({ title, value, percent, color }) => (
  <Card>
    <CardBody className="dashboard__card-widget">
      <div className="mobile-app-widget">
        <div className={`mobile-app-widget__top-line mobile-app-widget__top-line--${color}`}>
          <p className="mobile-app-widget__total-stat">{value}</p>
        </div>
        <div className="mobile-app-widget__title">
          <h5>{title}</h5>
        </div>
        <div className={`progress-wrap progress-wrap--small progress-wrap--${color} progress-wrap--label-top`}>
          <Progress value={percent}>
            <p className="progress__label primary">{percent}%</p>
          </Progress>
        </div>
      </div>
    </CardBody>
  </Card>
);

CountRequestType.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  value: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
};

CountRequestType.defaultProps = {
    color: "blue"
}


export default CountRequestType;
