import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Button, ButtonToolbar, Col, Container, Row } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import InvoiceModel from './components/InvoiceModel';

const Invoice = () => {
  const [component, setComponent] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container className="dashboard">
      <Row style={{marginBottom: '30px'}}>
        <Col md={12}>
          <h3 className="page-title">Fatura</h3>
        </Col>
      </Row>
      <Row>
        <InvoiceModel ref={(el) => setComponent(el)} />
        <ReactToPrint
          trigger={() => (
            <ButtonToolbar className="form__button-toolbar d-block w-100 mr-3">
              <Button color="primary float-right" type="button">
                Imprimir
              </Button>
            </ButtonToolbar>
          )}
          content={() => component}
        />
      </Row>
    </Container>
  );
};

export default Invoice;
