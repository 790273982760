// import Cookies from 'js-cookie'

export const TOKEN_KEY = "auth-token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
    localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.clear();
};

// export const TOKEN_KEY = "auth-token";
// export const isAuthenticated = () => Cookies.get(TOKEN_KEY) !== (null || undefined);
// export const getToken = () => Cookies.get(TOKEN_KEY);
// export const login = token => {
//     Cookies.set(TOKEN_KEY, token, { expires: 1 });
// };
// export const logout = () => {
//     Cookies.remove(TOKEN_KEY);
// };