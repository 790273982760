import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import axios from 'axios';
import qs from 'qs';
import LoadingIcon from 'mdi-react/LoadingIcon';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { format, parseISO } from 'date-fns';
import { getToken } from '../../../services/auth';

const NotesGraph = ({ inicialDate, finalDate, state, shopping, url }) => {
  const date = new Date();
  const today = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  const [data, setData] = useState([{ date: today, count: 0 }]);
  const [refresh, setRefresh] = useState(false);
  const formatData = useCallback(
    (res) => {
      const aux = [];
      if (res.length > 0)
        res.forEach((e) => {
          aux.push({
            date: format(parseISO(e.data), 'dd/MM/yyyy'),
            total: e.count
          });
        });
      else aux.push({ date: today, count: 0 });
      return aux;
    },
    [today]
  );

  const getGraphData = useCallback(() => {
    setRefresh(true);
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/${url}`;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { inicialDate, finalDate, state, shopping },
        paramsSerializer(params) {
          return qs.stringify(params, { encode: false });
        }
      })
      .then((res) => {
        setData(formatData(res.data));
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setRefresh(false));
  }, [inicialDate, finalDate, state, shopping, url, formatData]);

  useEffect(() => {
    const ac = new AbortController();
    getGraphData();
    return () => ac.abort();
  }, [getGraphData]);

  return (
    <Card>
      <CardBody className="panel__body">
        {refresh ? (
          <div className="panel__refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div className="panel__btns">
          <button
            className="panel__btn"
            aria-label="panel__btn"
            type="button"
            onClick={getGraphData}
          >
            <AutorenewIcon />
          </button>
        </div>
        <div className="panel__title">
          <h5 className="bold-text">Gráfico de leitura de Notas</h5>
        </div>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <ComposedChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="total" barSize={30} fill="#00AFEF" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </CardBody>
    </Card>
  );
};

NotesGraph.propTypes = {
  finalDate: PropTypes.string.isRequired,
  inicialDate: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  state: PropTypes.string,
  shopping: PropTypes.string
};

NotesGraph.defaultProps = {
  state: 'ALL',
  shopping: 'ALL'
};

export default NotesGraph;
