import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import FileDocumentOutline from 'mdi-react/FileDocumentOutlineIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { Card, CardBody, Col, Row } from 'reactstrap';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import { inputAccessKeyMask } from '../../../shared/utils/utils';
import CheckBox from '../../../shared/components/form/CheckBox';

const logo = `${process.env.PUBLIC_URL}/img/no-results.png`;

const NotFoundNote = ({ accessKeyFormated }) => {
  const { owner } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const config = localStorage.getItem('config')
    ? JSON.parse(localStorage.getItem('config'))
    : '';
  const [load, setLoad] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isToggleCheckboxEnabled, setIsToggleCheckboxEnabled] = useState(false);
  const { handleSubmit, control, register } = useForm({
    defaultValues: {
      accessKey: accessKeyFormated,
      callbackUrl: config?.callbackUrl || ''
    }
  });

  const handleToggleCheckBox = () => {
    setIsToggleCheckboxEnabled(!isToggleCheckboxEnabled);
  };

  const onSubmit = async (data) => {
    try {
      await setLoad(true);
      const body = {
        accessKey: accessKeyFormated,
        extraOptions: {
          forceWinnDelay: isToggleCheckboxEnabled
        }
      };
      if (data.callbackUrl) body.callbackUrl = data.callbackUrl;
      const apiUrl = `${process.env.REACT_APP_WAS_KEY}/create`;
      await axios.post(apiUrl, body, { headers: { 'x-api-key': owner } });
      await toast.success('Nota enviada com sucesso.', {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: false,
        hideProgressBar: true,
        theme: 'colored'
      });
    } catch (err) {
      await toast.error(
        'Ocorreu um erro interno, tente novamente mais tarde.',
        {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: false,
          hideProgressBar: true,
          theme: 'colored'
        }
      );
    } finally {
      await setLoad(false);
    }
  };

  const expandClass = classNames(
    {
      icon: true,
      expand: true,
      'expand--load': load
    },
    'square btn btn-success mb-0'
  );

  return (
    <>
      <ToastContainer />
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            {!showForm && (
              <div className="informations">
                <div className="card__title project-summary__card-title">
                  <h5 className="bold-text">Informações da Nota Fiscal</h5>
                </div>
                <div className="px-3">
                  <Row>
                    <Col md={12} className="text-center">
                      <img src={logo} alt="logo" style={{ width: '240px' }} />
                    </Col>
                    <Col md={12} className="text-center mt-3">
                      <p className="text-content m-0">
                        Essa nota não foi encontrada na nossa base, gostaria de
                        realizar o envio para a nossa API?
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="text-center mt-4">
                      <button
                        className={expandClass}
                        type="button"
                        disabled={load}
                        onClick={() => setShowForm(true)}
                        style={{
                          padding: '5px 60px'
                        }}
                      >
                        Enviar
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            {showForm && (
              <div className="informations">
                <div className="card__title project-summary__card-title">
                  <h5 className="bold-text">Formulário para envio</h5>
                </div>
                <Row>
                  <Col md={12} lg={12} xl={12}>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                      <Row className="account-row">
                        <Col md={12} lg={4} xl={4} className="account-col">
                          <div
                            className="form__form-group"
                            style={{ marginBottom: '0px' }}
                          >
                            <span className="form__form-group-label">
                              Chave de Acesso
                            </span>
                            <div
                              className="form__form-group-field"
                              style={{ paddingRight: '16px' }}
                            >
                              <div className="form__form-group-icon">
                                <FileDocumentOutline />
                              </div>
                              <Controller
                                name="accessKey"
                                control={control}
                                render={({ field }) => (
                                  <MaskedInput
                                    {...field}
                                    mask={inputAccessKeyMask}
                                    name="accessKey"
                                    type="text"
                                    disabled
                                    placeholder=""
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12} lg={4} xl={4} className="account-col">
                          <div
                            className="form__form-group"
                            style={{ marginBottom: '0px' }}
                          >
                            <span className="form__form-group-label">
                              Callback URL
                            </span>
                            <div
                              className="form__form-group-field"
                              style={{ paddingRight: '16px' }}
                            >
                              <input
                                name="callbackUrl"
                                type="text"
                                placeholder=""
                                {...register('callbackUrl')}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col
                          md={12}
                          lg={4}
                          xl={4}
                          className="account-col text-center"
                        >
                          <div
                            className="form__form-group"
                            style={{ marginBottom: '0px' }}
                          >
                            <span className="form__form-group-label">
                              Delay de 30 minutos para a leitura
                            </span>
                            <div
                              className="form__form-group-field justify-content-center"
                              style={{ marginTop: '4px' }}
                            >
                              <CheckBox
                                name="remember_me"
                                value={isToggleCheckboxEnabled}
                                onChange={handleToggleCheckBox}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className="text-center mt-5">
                          <button
                            className={expandClass}
                            type="submit"
                            disabled={load}
                            style={{
                              padding: '5px 60px'
                            }}
                          >
                            <LoadingIcon /> Enviar
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

NotFoundNote.propTypes = {
  accessKeyFormated: PropTypes.string
};

NotFoundNote.defaultProps = {
  accessKeyFormated: ''
};

export default NotFoundNote;
