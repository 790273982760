import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({
  title,
  icon,
  newLink,
  route,
  onClick,
  externalLink
}) => (
  <>
    {externalLink ? (
      <div onClick={onClick} aria-hidden="true">
        <li className="sidebar__link">
          {icon ? (
            <span className={`sidebar__link-icon lnr lnr-${icon}`} />
          ) : (
            ''
          )}
          <p className="sidebar__link-title" style={{ color: 'white' }}>
            {title}
            {newLink ? (
              <Badge className="sidebar__link-badge">
                <span>New</span>
              </Badge>
            ) : (
              ''
            )}
          </p>
        </li>
      </div>
    ) : (
      <NavLink
        to={{ pathname: route }}
        onClick={onClick}
        activeClassName="sidebar__link-active"
      >
        <li className="sidebar__link">
          {icon ? (
            <span className={`sidebar__link-icon lnr lnr-${icon}`} />
          ) : (
            ''
          )}
          <p className="sidebar__link-title" style={{ color: 'white' }}>
            {title}
            {newLink ? (
              <Badge className="sidebar__link-badge">
                <span>New</span>
              </Badge>
            ) : (
              ''
            )}
          </p>
        </li>
      </NavLink>
    )}
  </>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  externalLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  externalLink: false,
  route: '/',
  onClick: () => {}
};

export default SidebarLink;
