import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import axios from 'axios';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { PuffLoader } from 'react-spinners';
import Modal from '../../../shared/Modal';
import { getToken } from '../../../services/auth';

const PasswordCard = () => {
  const { email } = JSON.parse(localStorage.getItem('login')) || {};
  
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });
  const [open, setOpen] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [isNewConfirmPasswordShown, setIsNewConfirmPasswordShown] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [color] = useState('#00AFEF');

  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const handleShowNewPassword = () => {
    setIsNewPasswordShown(!isNewPasswordShown);
  };

  const handleShowNewConfirmPassword = () => {
    setIsNewConfirmPasswordShown(!isNewConfirmPasswordShown);
  };

  const resetForm = () => {
    setOpen(false);
    reset();
  }

  const onSubmit = (data) => {
    setLoading(true);
    const dataForm = { ...data, email };
    delete dataForm.confirmNewPassword;
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/changePassword`;
    axios
      .post(apiUrl, dataForm, {
      headers: { Authorization: `Bearer ${getToken()}` }
    })
      .then((res) => {
        if (res.data.updated === false) throw new Error('Erro Interno');
        setOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          setOpenError(error.response.data.message);
        } else {
          setOpenError(error.message);
        }
        setLoading(false);
      });
  };

  return loading ? (
    <div className="sweet-loading">
      <PuffLoader
        color={color}
        loading={loading}
        size={100}
        speedMultiplier={1.5}
      />
    </div>
  ) : (
    <>
      <div className="card__title">
        <h5 className="bold-text">Alterar senha</h5>
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Row className="account-row">
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Senha atual</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <input
                  name="password"
                  type={isPasswordShown ? 'text' : 'password'}
                  placeholder=""
                  {...register('password', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
                <button
                  className={`form__form-group-button${
                    isPasswordShown ? ' active' : ''
                  }`}
                  onClick={() => handleShowPassword()}
                  type="button"
                >
                  <EyeIcon />
                </button>
              </div>
              {errors.password && (
                <span className="error">{errors.password.message}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="account-row">
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Nova senha</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <input
                  name="newPassword"
                  type={isNewPasswordShown ? 'text' : 'password'}
                  placeholder=""
                  {...register('newPassword', {
                    required: 'Este campo é obrigatório.',
                    validate: (value) =>
                      value !== watch('password') || 'Nova senha não pode ser igual a atual'
                  })}
                />
                <button
                  className={`form__form-group-button${
                    isNewPasswordShown ? ' active' : ''
                  }`}
                  onClick={() => handleShowNewPassword()}
                  type="button"
                >
                  <EyeIcon />
                </button>
              </div>
              {errors.newPassword && (
                <span className="error">{errors.newPassword.message}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="account-row">
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">
                Confirme a nova senha
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <input
                  name="confirmNewPassword"
                  type={isNewConfirmPasswordShown ? 'text' : 'password'}
                  placeholder=""
                  {...register('confirmNewPassword', {
                    required: 'Este campo é obrigatório.',
                    validate: (value) =>
                      value === watch('newPassword') || 'Senhas não são iguais'
                  })}
                />
                <button
                  className={`form__form-group-button${
                    isNewConfirmPasswordShown ? ' active' : ''
                  }`}
                  onClick={() => handleShowNewConfirmPassword()}
                  type="button"
                >
                  <EyeIcon />
                </button>
              </div>
              {errors.confirmNewPassword && (
                <span className="error">
                  {errors.confirmNewPassword.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <ButtonToolbar className="form__button-toolbar d-block w-100">
            <Button color="primary float-left" type="submit" disabled={!isValid}>
            Atualizar
          </Button>
        </ButtonToolbar>
      </form>
      {open ? (
        <Modal
          color="success"
          title="Sucesso!"
          colored
          message="Sua senha foi alterada com sucesso."
          isOpened={open}
          toggleFunction={resetForm}  
        />
      ) : null}
      {openError ? (
        <Modal
          color="danger"
          title="Erro!"
          colored
          message={String(openError)}
          isOpened={openError}
          toggleFunction={()=> setOpenError(false)}  
        />
      ) : null}
    </>
  );
};

export default PasswordCard;
