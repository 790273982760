/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table
} from 'reactstrap';
import Cash from 'mdi-react/CashIcon';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { brMoneyMask, servicesDescripton } from '../../shared/utils/utils';
import { getToken } from '../../services/auth';

const PaymentInformation = () => {
  const { control } = useForm();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [valueRecharge, setValueRecharge] = useState(100);
  const [valueFloat, setValueFloat] = useState('100,00');
  const [showTablePrincing, setShowTablePrincing] = useState(false);
  const [tablePricingData, setTablePricingData] = useState([]);
  const [tablePackageData, setTablePackageData] = useState([]);
  const [loading, setLoading] = useState(true);

  const style = {
    color: '#2A2E4F',
    fontWeight: 600,
    alignItems: 'center',
    marginTop: '20px'
  };

  const calcBonus = (value) => {
    const recargaData = tablePackageData;
    if (recargaData.length === 0) return brMoneyMask(value.replace(',', ''));
    let nivelRecarga = null;

    for (let i = recargaData.length - 1; i >= 0; i -= 1) {
      if (
        recargaData[i].valueRecharge &&
        parseFloat(value.replace(',', '')) >=
          parseFloat(recargaData[i].valueRecharge.replace('.', ''))
      ) {
        nivelRecarga = recargaData[i];
        break;
      }
    }

    if (!nivelRecarga) {
      return brMoneyMask(value.replace(',', ''));
    }

    const bonus =
      (nivelRecarga.percentage / 100) * parseFloat(value.replace(',', ''));
    const valorComBonus = parseFloat(value.replace(',', '')) + bonus;
    return brMoneyMask(valorComBonus);
  };

  const calcPercent = (value) => {
    const recargaData = tablePackageData;
    if (recargaData.length === 0) return 0;
    let nivelRecarga = null;

    for (let i = recargaData.length - 1; i >= 0; i -= 1) {
      if (
        recargaData[i].valueRecharge &&
        parseFloat(value.replace(',', '')) >=
          parseFloat(recargaData[i].valueRecharge.replace('.', ''))
      ) {
        nivelRecarga = recargaData[i];
        break;
      }
    }

    if (!nivelRecarga) {
      return 0;
    }

    return nivelRecarga.percentage;
  };

  const isEven = (v) =>
    parseFloat(
      v.replaceAll('R$ ', '').replaceAll('.', '').replaceAll(',', ''),
      10
    ) > 0;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_WAS_KEY}/clientConfig`,
          {
            headers: { Authorization: `Bearer ${getToken()}` }
          }
        );

        const { requestPrice, ownerCreditBonus } = response.data;
        const transformedArray = Object.entries(requestPrice)
          .map(([serviceName, value]) => ({
            serviceName: servicesDescripton[serviceName],
            value: `R$ ${brMoneyMask(value)}`
          }))
          .filter((service) => service.serviceName !== 'enabled');

        setTablePricingData(transformedArray);

        let ownerCreditBonusFormat = ownerCreditBonus;

        ownerCreditBonusFormat = ownerCreditBonusFormat
          .map((item) => ({
            ...item,
            targetValue: `R$ ${brMoneyMask(item.targetValue)}`,
            valueRecharge: (item.targetValue / 100).toFixed(2),
            percentage: item.bonusPercentage,
            bonusPercentage: `${item.bonusPercentage}%`,
            calculatedBonus: `R$ ${brMoneyMask(
              item.targetValue + (item.targetValue * item.bonusPercentage) / 100
            )}`
          }))
          .sort((a, b) => a.targetValue - b.targetValue);

        ownerCreditBonusFormat.unshift({
          targetValue: `<= R$ ${brMoneyMask(
            parseFloat(
              ownerCreditBonusFormat[0].targetValue
                .replaceAll('R$ ', '')
                .replaceAll('.', '')
                .replaceAll(',', '')
            ) - 1
          )}`,
          bonusPercentage: '-',
          calculatedBonus: '-',
          percentage: 0
        });

        setTablePackageData(ownerCreditBonusFormat);
      } catch (e) {
        throw new Error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onSubmit = async () => {
    const amount = valueFloat.replaceAll(',', '').replaceAll('.', '');
    if (amount >= 500) {
      await localStorage.setItem('amount', JSON.stringify(amount));
      await history.push('/recharge', amount);
    }
  };

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '30px' }}>
        <Col md={6} col={12}>
          <h3 style={style}>Informações da Recarga</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={8}>
          <Row>
            <Col xl={12} className="mb-4">
              <Card className="p-0">
                <CardBody>
                  <Row className="align-items-center">
                    <Col sm={12} xl={8}>
                      <h5 className="payment_info-title-card-2">
                        Preços e descontos dos pacotes
                      </h5>
                    </Col>
                    <Col sm={12} xl={4}>
                      <Button
                        className="table-button-payment-change"
                        onClick={() => setShowTablePrincing(!showTablePrincing)}
                      >
                        {showTablePrincing
                          ? 'Tabela de pacotes'
                          : 'Tabela de preços'}
                      </Button>
                    </Col>
                  </Row>
                  {loading ? (
                    <div style={{ height: '15rem' }}>
                      <div className="panel__refresh">
                        <LoadingIcon />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="payment_info mt-3">
                    {!loading && (
                      <>
                        {showTablePrincing ? (
                          <Table bordered hover responsive>
                            <thead>
                              <tr className="table-header">
                                <th>Serviço</th>
                                <th>Valor</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tablePricingData.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.serviceName}</td>
                                  <td>{item.value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <Table bordered hover responsive>
                            <thead>
                              <tr className="table-header">
                                <th>Valor</th>
                                <th>Bônus</th>
                                <th>Valor com Bônus</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tablePackageData.map((item, index) => (
                                <tr
                                  key={index}
                                  className={
                                    item.recommended ? 'table-info' : ''
                                  }
                                >
                                  <td>
                                    {item.targetValue}{' '}
                                    {item.recommended === 1 && (
                                      <Badge className="badge-info">
                                        Recomendado
                                      </Badge>
                                    )}
                                  </td>
                                  <td>{item.bonusPercentage}</td>
                                  <td>{item.calculatedBonus}</td>
                                  <td>
                                    {index !== 0 && (
                                      <Button
                                        className={
                                          item.recommended
                                            ? 'table-button-payment-warning'
                                            : 'table-button-payment'
                                        }
                                        onClick={() => {
                                          setValueRecharge(item.valueRecharge);
                                        }}
                                      >
                                        Selecionar
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={12} className="mb-4">
              <CardBody>
                <h5 className="payment_info-title-card-2">Recarga Manual</h5>
                <Row>
                  <Col md={12} lg={4} className="account-col mt-4 payment_info">
                    <div className="form">
                      <div
                        className="form__form-group"
                        style={{ marginBottom: '0px' }}
                      >
                        <span className="subtitle">Valor da recarga</span>
                        <div className="form__form-group-field mt-2">
                          <div className="form__form-group-icon">
                            <Cash />
                          </div>
                          <Controller
                            name="amount"
                            rules={{
                              required: 'Este campo é obrigatório.',
                              validate: isEven
                            }}
                            control={control}
                            render={({ field: { onChange, onBlur, ref } }) => (
                              <NumericFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                prefix="R$ "
                                placeholder="R$ 0,00"
                                className="input-info-payment"
                                allowNegative={false}
                                value={valueRecharge}
                                onValueChange={(values) => {
                                  onChange(values.floatValue);
                                  if (values.floatValue > 0) {
                                    if (values.floatValue < 5)
                                      setErrorMessage(
                                        'O Valor mínimo de recarga é R$ 5,00'
                                      );
                                    else setErrorMessage(null);
                                    setValueRecharge(
                                      values.floatValue
                                        .toFixed(2)
                                        .replace('.', ',')
                                    );
                                    setValueFloat(
                                      values.floatValue
                                        .toFixed(2)
                                        .replace('.', ',')
                                        .replace('R$ ', '')
                                    );
                                  } else {
                                    setErrorMessage(
                                      'O Valor mínimo de recarga é R$ 5,00'
                                    );
                                    setValueFloat('0,00');
                                    setValueRecharge(0);
                                  }
                                }}
                                onBlur={onBlur}
                                getInputRef={ref}
                              />
                            )}
                          />
                        </div>
                        {errorMessage && (
                          <span className="error">{errorMessage}</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={12} xl={4}>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody className="payment_info">
                  <h5 className="payment_info-title-card">
                    Sumário da recarga
                  </h5>
                  <hr style={{ borderColor: 'lightgray' }} />
                  <div className="mt-4 info-content">
                    <div className="flex items-center justify-between pb-3">
                      <span>Crédito com Bônus</span>
                      <span
                        className="float-right"
                        style={{ fontWeight: '500' }}
                      >
                        R$ {calcBonus(valueFloat)}
                      </span>
                    </div>
                    <div
                      className="flex items-center justify-between"
                      style={{ color: '#00AFEF' }}
                    >
                      <span>Bônus</span>
                      <span
                        className="float-right"
                        style={{ fontWeight: '500' }}
                      >
                        {calcPercent(valueFloat)}%
                      </span>
                    </div>
                  </div>
                  <hr style={{ borderColor: 'lightgray' }} />
                  <div className="info-content flex align-center justify-between">
                    <span className="align-center">Valor a pagar</span>
                    <span
                      className="align-center float-right"
                      style={{ fontWeight: '500' }}
                    >
                      R$ {brMoneyMask(valueFloat)}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary mt-4 w-100"
                    disabled={errorMessage}
                    onClick={() => onSubmit()}
                  >
                    Prosseguir
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentInformation;
