/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import RegisterForm from './components/RegisterForm';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const onSubmit = (loadingState) => {
    setLoading(loadingState);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card" style={{ maxWidth: '800px' }}>
          <div className="account__head">
            <h3 className="account__title">
              {loading ? (
                <span className="account__logo" style={{ color: '#00AFEF' }}>
                  Salvando os seus
                  <span className="account__logo-accent"> dados</span>
                </span>
              ) : (
                <span className="account__logo" style={{ color: '#00AFEF' }}>
                  Preencha com os seus
                  <span className="account__logo-accent"> dados</span>
                </span>
              )}
            </h3>
          </div>
          <RegisterForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Register;
