import React from 'react';
import { Container, Row } from 'reactstrap';
import ExampleCard from './components/Checkout';

const CheckoutConfirmation = () => (
  <Container className="dashboard">
    <Row>
      <ExampleCard />
    </Row>
  </Container>
);

export default CheckoutConfirmation;
