/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import ForgetPasswordForm from './components/ForgetPasswordForm';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const onSubmit = (loadingState) => {
    setLoading(loadingState);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card" style={{ maxWidth: '800px' }}>
          <div className="account__head">
            <h3 className="account__title">
              {loading ? (
                <span className="account__logo" style={{ color: '#00AFEF' }}>
                  Enviando e-mail para 
                  <span className="account__logo-accent"> redefinição de senha</span>
                </span>
              ) : (
                <span className="account__logo" style={{ color: '#00AFEF' }}>
                  Redefinição de
                  <span className="account__logo-accent"> senha</span>
                </span>
              )}
            </h3>
          </div>
          <ForgetPasswordForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Register;
