import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { format } from 'date-fns';

import { getToken } from '../../../services/auth';
import { paymentPackages } from '../../../shared/utils/utils';

const Checkout = () => {
  const { name, address } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';

  const formatAddress = address && JSON.parse(address);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const formatDate = (date) =>
    date && format(new Date(date), 'dd/MM/yyyy HH:mm:ss');

  useEffect(() => {
    // setLoading(true);
    const fetchData = async () => {
      const { search } = window.location;
      const params1 = new URLSearchParams(search);
      const transactionID = params1.get('transaction_id');
      const apiUrl = `${process.env.REACT_APP_WAS_KEY}/informTransactionCode/${transactionID}`;
      const res = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getToken()}` }
      });
      setData(res.data);
    };

    fetchData()
      .catch((e) => {
        throw e;
      })
      .finally(setLoading(false));
  }, []);
  return (
    <>
      {loading ? (
        ''
      ) : (
        <Col md={12}>
          <div className="checkout">
            <div className="container">
              <Row className="d-flex justify-content-center">
                <Col md={8} className="mt-5 mb-5">
                  <div className="card">
                    <div className="text-left logo p-4 px-5" />
                    <div className="invoice p-5">
                      <h5>Sua solicitação foi realizada!</h5>{' '}
                      <span className="font-weight-bold d-block mt-4">
                        Olá, {name}
                      </span>{' '}
                      <span>
                        Sua solicitação chegou até nós, agora estamos verificando e
                        logo as suas consultas estarão dísponiveis!
                      </span>
                      <div className="payment border-top mt-3 mb-3 border-bottom table-responsive">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <div className="py-2">
                                  <span className="d-block text-muted">
                                    Dia da Compra
                                  </span>
                                  <span>{formatDate(data.date)}</span>
                                </div>
                              </td>
                              <td>
                                <div className="py-2">
                                  <span className="d-block text-muted">
                                    Nº da transação
                                  </span>
                                  <span>{data.code}</span>
                                </div>
                              </td>
                              <td>
                                <div className="py-2">
                                  <span className="d-block text-muted">
                                    Pagamento
                                  </span>
                                  <span>{data.paymentMethod_type}</span>
                                </div>
                              </td>
                              <td>
                                <div className="py-2">
                                  <span className="d-block text-muted">
                                    Endereço de cobrança
                                  </span>
                                  <span>
                                    {formatAddress.street},{' '}
                                    {formatAddress.streetNumber} -{' '}
                                    {formatAddress.city}- BR
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="product border-bottom table-responsive">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td width="80%">
                                <span className="font-weight-bold">
                                  Pacote API{' '}
                                  {data.reference &&
                                    paymentPackages[
                                      data.reference.split('=')[1]
                                    ].formatName}
                                </span>
                                <div className="product-qty">
                                  <span className="d-block">Quantidade:1</span>
                                </div>
                              </td>
                              <td width="20%">
                                <div className="text-right">
                                  <span className="font-weight-bold">
                                    {data.reference &&
                                      paymentPackages[
                                        data.reference.split('=')[1]
                                      ].value}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <Row className="d-flex justify-content-end">
                        <Col md={5}>
                          <table className="table table-borderless">
                            <tbody className="totals">
                              <tr className="border-bottom">
                                <td>
                                  <div className="text-left">
                                    <span className="font-weight-bold">
                                      Total
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="text-right">
                                    <span className="font-weight-bold">
                                      {data.reference &&
                                        paymentPackages[
                                          data.reference.split('=')[1]
                                        ].value}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <p>
                        Quando a sua compra for confirmada, você receberá um
                        email de confirmação.
                      </p>
                      <p className="font-weight-bold mb-0">
                        Obrigado por comprar com a gente!
                      </p>
                      <span>Winn</span>
                    </div>
                    <div className="d-flex justify-content-between footer p-3">
                      <span>
                        Ficou com dúvidas? entre em contato{' '}
                        <b>atendimento@winn.com.vc</b>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default Checkout;
