import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import axios from 'axios';
import LoadingIcon from 'mdi-react/LoadingIcon';
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'date-fns';
import { getToken } from '../../../services/auth';
import { JSONA, normalizeName, ShoppingCode, translatedStatusAPI } from '../../../shared/utils/utils';

const NoteInformation = ({ note, accessKeyFormated }) => {
  const [load, setLoad] = useState(false);

  const sendBackToAPI = async () => {
    try {
      await setLoad(true);
      const apiUrlOnSubmit2 = `${process.env.REACT_APP_WAS_KEY}/handlerNota`;
      await axios.post(
        apiUrlOnSubmit2,
        {
          accessKey: accessKeyFormated,
          status: translatedStatusAPI(note.status)
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` }
        }
      );
      await toast.success(
        `Nota ${
          note.status === 'Realizado' ? 'Enviada' : 'Reprocessada'
        } com sucesso.`,
        {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: false,
          hideProgressBar: true,
          theme: 'colored'
        }
      );
    } catch {
      await toast.error(
        'Ocorreu um erro interno, tente novamente mais tarde.',
        {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: false,
          hideProgressBar: true,
          theme: 'colored'
        }
      );
    } finally {
      await setLoad(false);
    }
  };

  const expandClass = classNames(
    {
      icon: true,
      expand: true,
      'expand--load': load
    },
    'square btn btn-success mb-0'
  );

  return (
    <>
      <ToastContainer />
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <div className="informations">
              <div className="card__title project-summary__card-title">
                <h5 className="bold-text">Informações da Nota Fiscal</h5>
              </div>
              <div className="px-3">
                <Row>
                  <Col md={12} lg={6} xl={6}>
                    <Row>
                      <Col md={12} className="pb-1 px-0">
                        <p className="content m-0">Chave de Acesso:</p>
                      </Col>
                      <Col md={12} className="pb-3 px-0">
                        <p className="text-content m-0">{note.accessKey}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} lg={6} xl={6}>
                    <Row>
                      <Col md={12} className="pb-1 px-0">
                        <p className="content m-0">Data de Criação:</p>
                      </Col>
                      <Col md={12} className="pb-3 px-0">
                        <p className="text-content m-0">
                          {format(
                            new Date(note.createdAt),
                            'dd/MM/yyyy HH:mm:ss'
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} lg={6} xl={6}>
                    <Row>
                      <Col md={12} className="pb-1 px-0">
                        <p className="content m-0">Data da Leitura:</p>
                      </Col>
                      <Col md={12} className="pb-3 px-0">
                        <p className="text-content m-0">
                          {format(
                            new Date(note.updatedAt),
                            'dd/MM/yyyy HH:mm:ss'
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} lg={6} xl={4}>
                    <Row>
                      <Col md={12} className="pb-1 px-0">
                        <p className="content m-0">Status:</p>
                      </Col>
                      <Col md={12} className="pb-3 px-0">
                        <p className="text-content m-0">{note.status}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} lg={6} xl={2}>
                    <Row>
                      <Col md={12} className="pb-1 px-0">
                        <p className="content m-0">Estado:</p>
                      </Col>
                      <Col md={12} className="pb-3 px-0">
                        <p className="text-content m-0">
                          {JSONA[note.accessKey.substring(0, 2)].name}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  {note.extraOptions?.shopping && (
                    <Col md={12} lg={6} xl={12}>
                      <Row>
                        <Col md={12} className="pb-1 px-0">
                          <p className="content m-0">Shopping:</p>
                        </Col>
                        <Col md={12} className="pb-3 px-0">
                          <p className="text-content m-0">
                            {normalizeName(ShoppingCode[note.extraOptions.shopping])}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={12} className="text-center mt-4">
                    <button
                      className={expandClass}
                      type="button"
                      disabled={load}
                      onClick={sendBackToAPI}
                      style={{
                        padding: '5px 60px'
                      }}
                    >
                      <LoadingIcon />
                      {note.status === 'Realizado' ? 'Enviar' : 'Reprocessar'}
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

NoteInformation.propTypes = {
  note: PropTypes.shape({
    accessKey: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    extraOptions: PropTypes.shape({
      shopping: PropTypes.string,
    })
  }),
  accessKeyFormated: PropTypes.string
};

NoteInformation.defaultProps = {
  note: {},
  accessKeyFormated: '',
};
export default NoteInformation;
