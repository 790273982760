import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileCard';
import ProfileTabs from './components/ProfileTabs';

const Profile = () => { 
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
  <Container className="dashboard">
    <Row>
      <Col md={12} lg={12} xl={4}>
        <Row>
          <ProfileMain />
        </Row>
      </Col>
      <ProfileTabs />
    </Row>
  </Container>
);
}

export default Profile;
