import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import invoices from '../../../shared/utils/invoiceData';

const logo = `${process.env.PUBLIC_URL}/img/wlogo.png`;

const InvoiceModel = React.forwardRef((props, ref) => {
  const { name, address } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';

  return (
    <Col md={12}>
      <Card>
        <CardBody className="p-0">
          <div className="invoice" ref={ref}>
            <div id="container">
              <br />
              <br />

              <section id="memo">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>

                <div className="company-info">
                  <div>Winn Meios de Pagamento</div>

                  <br />

                  <span>Rua Rua Ceará, 244 - </span>
                  <span> Alphaville Empresarial - Barueri,SP</span>

                  <br />

                  <span>06465-120 | </span>
                  <span>www.winn.com.vc</span>
                </div>
              </section>

              <br />
              <br />
              <br />

              <section id="invoice-title-number">
                <span id="title">FATURA</span>
                <span id="number"># {invoices[1].id}</span>
              </section>

              <div className="clearfix" />

              <br />
              <br />
              <br />

              <section id="client-info">
                <span>Endereço para faturamento:</span>
                <div>
                  <span className="bold">{name}</span>
                </div>

                <div>
                  <span>
                    {address.street}, {address.streetNumber}
                  </span>
                </div>

                <div>
                  <span>
                    {address.neighborhood}, {address.city} - {address.state}
                  </span>
                </div>

                <div>
                  <span>{address.zipCode}</span>
                </div>
              </section>

              <div className="clearfix" />

              <br />
              <br />
              <br />

              <section id="items">
                <table cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Descricão</th>
                      <th>Validações</th>
                      <th>Valor</th>
                    </tr>
                  </thead>

                  <tbody>
                    {invoices[1].services.map((service) => (
                      <tr>
                        <td>1</td>
                        <td>{service.description}</td>
                        <td>{service.validations}</td>
                        <td>{service.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>

              <br />
              <br />
              <br />

              <section id="sums">
                <table cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr data-hide-on-quote="true">
                      <th>Total:</th>
                      <th>R$ 30.000,00</th>
                    </tr>
                  </thead>
                </table>

                <div className="clearfix" />
              </section>

              <section id="invoice-info">
                <div>
                  <span>Data de Emissão: </span> <span>A definir</span>
                </div>
                <div>
                  <span>Data de Vencimento: </span> <span>A definir</span>
                </div>
              </section>

              <div className="clearfix" />

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
});

export default InvoiceModel;
