/* eslint-disable */
import React, { useState } from 'react';
import AtIcon from 'mdi-react/AtIcon';
import { useForm } from 'react-hook-form';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import PuffLoader from 'react-spinners/PuffLoader';
import axios from 'axios';
import Modal from '../../../shared/Modal';

const ForgetPasswordForm = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [color] = useState('#00AFEF');
  const history = useHistory();

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const backToLogin = () => {
    history.push('/login');
  }

  const onSubmit = (data) => {
    setLoading(true);
    props.onSubmit(true);
    const dataForm = data;
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/forgetPassword`;
    axios
      .post(apiUrl, dataForm)
      .then((res) => {
        if (res.data.created === false) throw new Error('Erro Interno');
        setOpen(true);
      })
      .catch(() => {
        setOpenError(true);
        setLoading(false);
        props.onSubmit(false);
      });
  };

  return loading ? (
    <div className="sweet-loading">
      <PuffLoader
        color={color}
        loading={loading}
        size={100}
        speedMultiplier={1.5}
      />
      {open ? (
        <Modal
          color="primary"
          title="Sucesso!"
          colored
          message="Enviamos um e-mail para redefinir sua senha, por favor verifique sua caixa de entrada."
          isOpened={open}
          toggleFunction={backToLogin}  
        />
      ) : null}
    </div>
  ) : (
    <>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AtIcon />
            </div>
            <input
              name="email"
              type="text"
              placeholder=""
              {...register('email', {
                required: 'Este campo é obrigatório.',
                pattern: {
                  value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  message: 'Insira um email válido.'
                }
              })}
            />
          </div>
          {errors.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </div>
        <button
          className="btn btn-primary account__btn account__btn--small"
          type="submit"
        >
          Confirmar
        </button>
        <span
          className="form__form-group-description"
          style={{ fontWeight: 600 }}
        >
          <a href="/">Voltar para o Login</a>
        </span>
      </form>
      {openError ? (
        <Modal
          color="danger"
          title="Erro!"
          colored
          message="Ocorreu um erro interno, por favor aguarde um momento ou tente novamente mais tarde."
          isOpened={openError}
        />
      ) : null}
    </>
  );
};

ForgetPasswordForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onSubmit: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default withRouter(ForgetPasswordForm);
