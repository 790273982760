import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Col, Container, Row } from 'reactstrap';
import SearchNote from './components/SearchNote';

const Operation = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '30px' }}>
        <Col xs={12} md={12} xl={10}>
          <h3 className="page-title">Operacional</h3>
        </Col>
      </Row>
      <Row>
        <SearchNote />
      </Row>
    </Container>
  );
};
export default Operation;
