/* eslint-disable no-console */
import React from 'react';
import LogInForm from './components/LogInForm';

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            <span className="account__logo" style={{ color: '#00AFEF' }}>
              Winn
              <span className="account__logo-accent">Global</span>
            </span>
          </h3>
        </div>
        <LogInForm />
      </div>
    </div>
  </div>
);

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
