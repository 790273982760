import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import MaskedInput from 'react-text-mask';

const InformationAccount = () => {
  const { name, address, owner } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const { email } = localStorage.getItem('login')
    ? JSON.parse(localStorage.getItem('login'))
    : '';
  const defaultValues = {
    name,
    email,
    key: owner,
    zipCode: JSON.parse(address).zipCode || '',
    street: JSON.parse(address).street || '',
    streetNumber: JSON.parse(address).streetNumber || '',
    complement: JSON.parse(address).complement || '',
    neighborhood: JSON.parse(address).neighborhood || '',
    city: JSON.parse(address).city || '',
    state: JSON.parse(address).state || ''
  };
  const { register, handleSubmit, control } = useForm({
    defaultValues
  });
  const onSubmit = () => {};

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Informações da conta</h5>
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Row className="account-row">
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Nome</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="name"
                  type="text"
                  placeholder=""
                  disabled
                  {...register('name', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="email"
                  type="text"
                  placeholder=""
                  disabled
                  {...register('email', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
            </div>
          </Col>
          <Col md={12} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">x-api-key</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="key"
                  type="text"
                  placeholder=""
                  disabled
                  {...register('key', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="account-row">
          <Col md={4} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">CEP</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <Controller
                  name="zipCode"
                  rules={{ required: 'Este campo é obrigatório.' }}
                  control={control}
                  render={({ field }) => (
                    <MaskedInput
                      {...field}
                      mask={[
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/
                      ]}
                      name="zipCode"
                      disabled
                      type="text"
                      placeholder=""
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Logradouro</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="street"
                  disabled
                  type="text"
                  placeholder=""
                  {...register('street', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
            </div>
          </Col>
          <Col md={2} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Número</span>
              <div className="form__form-group-field">
                <input
                  name="streetNumber"
                  disabled
                  type="text"
                  placeholder=""
                  {...register('streetNumber', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Complemento</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="complement"
                  disabled
                  type="text"
                  placeholder=""
                  {...register('complement')}
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Bairro</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="neighborhood"
                  disabled
                  type="text"
                  placeholder=""
                  {...register('neighborhood', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Cidade</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="city"
                  disabled
                  type="text"
                  placeholder=""
                  {...register('city', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Estado</span>
              <div className="form__form-group-field">
                <input
                  name="state"
                  disabled
                  type="text"
                  placeholder=""
                  {...register('state', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* <ButtonToolbar className="form__button-toolbar d-block w-100">
          <Button color="primary float-right" type="submit">
            Atualizar
          </Button>
        </ButtonToolbar> */}
      </form>
    </>
  );
};

export default InformationAccount;
