import React, { useEffect, useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { jwtDecode } from 'jwt-decode';
import TopbarMenuLink from './TopbarMenuLink';
import { getToken, logout } from '../../../services/auth';

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [type, setType] = useState('');
  const { name, logo } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const Ava = logo || `${process.env.PUBLIC_URL}/img/user_avatar.png`;

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logoutAccount = () => {
    logout();
  };

  const checkToken = () => {
    const decodedToken = jwtDecode(getToken());
    if (decodedToken.sub.chargeType) {
      setType(decodedToken.sub.chargeType);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div className="topbar__profile">
      <button
        type="button"
        className="topbar__avatar"
        onClick={handleToggleCollapse}
      >
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        <p className="topbar__avatar-name">{name}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="Perfil" icon="user" path="/profile" />
          {type === 'CREDIT' && (
            <>
              <TopbarMenuLink
                title="Historico"
                icon="history"
                path="/historic-payments"
              />
              <TopbarMenuLink
                title="Consumo"
                icon="database"
                path="/historic-consumption"
              />
            </>
          )}
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Sair"
            icon="exit"
            path="/login"
            onClick={() => logoutAccount()}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
