/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import {
  useLocation,
  useHistory
} from 'react-router-dom/cjs/react-router-dom.min';
import { PuffLoader } from 'react-spinners';
import ReactCreditCards from 'react-credit-cards-2';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { getToken } from '../../services/auth';
import CheckBox from '../../shared/components/form/CheckBox';

const schema = yup.object().shape({
  number: yup
    .string()
    .required('Número do cartão é obrigatório')
    .matches(/^[0-9\s]{19}$/, 'O número do cartão deve ter 16 dígitos'),
  name: yup
    .string()
    .required('Nome é obrigatório')
    .min(3, 'O nome deve ter pelo menos 3 caracteres'),
  expiry: yup
    .string()
    .required('Validade é obrigatória')
    .matches(
      /^(0[1-9]|1[0-2])\/([0-9]{4})$/,
      'A validade deve estar no formato MM/YYYY'
    ),
  cvc: yup
    .string()
    .required('CVC é obrigatório')
    .min(3, 'O CVC deve ter no mínimo 3 dígitos')
    .max(4, 'O CVC deve ter no máximo 4 dígitos')
});

const CreditCardPayment = () => {
  const client = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';

  const {
    register,
    setValue,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const [color] = useState('#00AFEF');
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isToggleCheckboxEnabled, setIsToggleCheckboxEnabled] = useState(false);
  const [cards, setCards] = useState([]);
  const [focused, setFocused] = useState('');
  const [formData, setFormData] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: ''
  });
  const history = useHistory();
  const location = useLocation();

  const handleToggleCheckBox = () => {
    setIsToggleCheckboxEnabled(!isToggleCheckboxEnabled);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSelect = (id) => {
    setSelectedOption(id);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    setAmount(parseFloat(location.state.amount));
    const { CancelToken } = axios;
    const source = CancelToken.source();
    async function fetchMyAPI() {
      try {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_WAS_KEY}/wallet/credit-card`;
        const res = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': client.owner
          },
          cancelToken: source.token
        });
        res.data.push({
          newCreditCard: true
        });
        for (let i = 0; i < res.data.length; i += 1) {
          res.data[i].id = i + 1;
        }
        setCards(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    fetchMyAPI();
    return () => {
      source.cancel();
    };
  }, [client.owner, location.state]);

  const hashCreditCard = (galaxPay, card) =>
    new Promise((resolve, reject) => {
      galaxPay.hashCreditCard(
        card,
        (hash) => resolve(hash),
        (error) => reject(error)
      );
    });

  const onSubmit = async () => {
    const token = process.env.REACT_APP_GALAX_PAY;
    const env = process.env.REACT_APP_ENV;
    const galaxPay = new GalaxPay(token, env === 'production');
    try {
      setLoading(true);
      const newCreditCardID = cards[cards.length - 1].id;
      let hash;
      if (newCreditCardID === selectedOption) {
        const isValid = await trigger();
        if (isValid) {
          const card = await galaxPay.newCard({
            number: formData.number,
            holder: formData.name,
            expiresAt: formData.expiry.split('/').reverse().join('-'),
            cvv: formData.cvc
          });
          hash = await hashCreditCard(galaxPay, card);
        }
      }
      const selectedCard = cards.find((card) => card.id === selectedOption);
      const apiUrl = `${process.env.REACT_APP_WAS_KEY}/wallet/charge/credit-card`;

      await axios.post(
        apiUrl,
        {
          amount,
          ...(newCreditCardID === selectedOption
            ? {
                creditCardHash: hash,
                useForNextPurchases: isToggleCheckboxEnabled
              }
            : {
                creditCardId: selectedCard.internalId
              })
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': client.owner
          }
        }
      );
      toast.success(`Pagamento realizado com sucesso.`, {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: false,
        hideProgressBar: true,
        theme: 'colored'
      });
      await new Promise((resolve) => {
        setTimeout((r) => {
          resolve(r);
        }, 3000);
      });
      await await history.push('/');
    } catch (error) {
      toast.error(`Ocorreu um erro ao processar o pagamento.`, {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: false,
        hideProgressBar: true,
        theme: 'colored'
      });
      throw error.message;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Container className="dashboard">
        <Row style={{ marginBottom: '10px' }}>
          <Col xs={12} md={12} xl={10}>
            <h3 className="page-title" style={{ textTransform: 'none' }}>
              Cartão de crédito
            </h3>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                {loading ? (
                  <Row>
                    <Col
                      xl={12}
                      className="d-flex text-center align-items-center justify-content-center my-4"
                    >
                      <div
                        className="sweet-loading"
                        style={{ height: '500px' }}
                      >
                        <PuffLoader
                          color={color}
                          loading={loading}
                          size={100}
                          speedMultiplier={1.5}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col md={12} lg={12} xl={12}>
                      <div className="form">
                        <Row className="w-100">
                          <Col md={12} lg={12} xl={12}>
                            <div className="form__form-group">
                              <span className="payment_info-title-card-2">
                                Selecione o cartão de crédito
                              </span>
                            </div>
                            {cards.map((card) => (
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                xl={6}
                                key={card.id}
                                className={`p-0 card-option animated-card-option ${
                                  selectedOption === card.id ? 'is-open' : ''
                                }`}
                              >
                                <Label
                                  key={card.id}
                                  className={`options-credit-card ${
                                    selectedOption === card.id ? 'selected' : ''
                                  }`}
                                >
                                  <Row className="align-items-center ml-2">
                                    <Col
                                      xl={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="d-flex"
                                    >
                                      <input
                                        type="radio"
                                        name="paymentMethod"
                                        className="radio-customized-payment"
                                        value={card.id}
                                        checked={selectedOption === card.id}
                                        onChange={() => handleSelect(card.id)}
                                      />
                                      <span className="title-payment">
                                        {card.newCreditCard
                                          ? 'Cadastrar novo Cartão'
                                          : `Cartão com final ${
                                              card.number.split('******')[1]
                                            }`}
                                      </span>
                                    </Col>
                                    {selectedOption === card.id && (
                                      <>
                                        {!card.newCreditCard && (
                                          <Col xl={12} className="mt-4">
                                            <div className="credit-card-container">
                                              <ReactCreditCards
                                                number={card.number}
                                                expiry={card.expiresAt
                                                  .split('-')
                                                  .reverse()
                                                  .join('/')}
                                                locale={{ valid: 'Validade' }}
                                                placeholders={{
                                                  name: ''
                                                }}
                                              />
                                            </div>
                                          </Col>
                                        )}
                                        {card.newCreditCard && (
                                          <Row className="mt-4">
                                            <Col
                                              xl={6}
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              className="mt-2"
                                            >
                                              <ReactCreditCards
                                                number={formData.number.replace(
                                                  /\s/g,
                                                  ''
                                                )}
                                                name={formData.name}
                                                expiry={formData.expiry}
                                                cvc={formData.cvc}
                                                focused={focused}
                                                locale={{ valid: 'Validade' }}
                                                placeholders={{
                                                  name: 'Nome do cartão'
                                                }}
                                              />
                                            </Col>
                                            <Col
                                              xl={6}
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                              }}
                                              className="mt-2"
                                            >
                                              <form className="form">
                                                <Col
                                                  md={12}
                                                  className="account-col"
                                                >
                                                  <div
                                                    className="form__form-group"
                                                    style={{
                                                      marginBottom: '10px'
                                                    }}
                                                  >
                                                    <div className="form__form-group-field">
                                                      <InputMask
                                                        mask="9999 9999 9999 9999"
                                                        value={formData.number}
                                                        onChange={(e) => {
                                                          handleInputChange(e);
                                                          setValue(
                                                            'number',
                                                            e.target.value
                                                          );
                                                        }}
                                                        onFocus={(e) =>
                                                          setFocused(
                                                            e.target.name
                                                          )
                                                        }
                                                      >
                                                        {() => (
                                                          <input
                                                            type="tel"
                                                            name="number"
                                                            placeholder="Número do Cartão"
                                                            className="bg-white"
                                                          />
                                                        )}
                                                      </InputMask>
                                                    </div>
                                                    {errors.number && (
                                                      <p className="error">
                                                        {errors.number.message}
                                                      </p>
                                                    )}
                                                  </div>
                                                </Col>
                                                <Col
                                                  md={12}
                                                  className="account-col"
                                                >
                                                  <div
                                                    className="form__form-group"
                                                    style={{
                                                      marginBottom: '10px'
                                                    }}
                                                  >
                                                    <div className="form__form-group-field">
                                                      <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Nome no Cartão"
                                                        className="bg-white"
                                                        {...register('name')}
                                                        value={formData.name}
                                                        onChange={
                                                          handleInputChange
                                                        }
                                                        onFocus={(e) =>
                                                          setFocused(
                                                            e.target.name
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    {errors.name && (
                                                      <p className="error">
                                                        {errors.name.message}
                                                      </p>
                                                    )}
                                                  </div>
                                                </Col>
                                                <Col
                                                  md={6}
                                                  className="account-col"
                                                >
                                                  <div
                                                    className="form__form-group"
                                                    style={{
                                                      marginBottom: '10px'
                                                    }}
                                                  >
                                                    <div className="form__form-group-field">
                                                      <InputMask
                                                        mask="99/9999"
                                                        value={formData.expiry}
                                                        onChange={(e) => {
                                                          handleInputChange(e);
                                                          setValue(
                                                            'expiry',
                                                            e.target.value
                                                          );
                                                        }}
                                                        onFocus={(e) =>
                                                          setFocused(
                                                            e.target.name
                                                          )
                                                        }
                                                      >
                                                        {() => (
                                                          <input
                                                            type="tel"
                                                            name="expiry"
                                                            className="bg-white"
                                                            placeholder="Validade (MM/YYYY)"
                                                          />
                                                        )}
                                                      </InputMask>
                                                    </div>
                                                    {errors.expiry && (
                                                      <p className="error">
                                                        {errors.expiry.message}
                                                      </p>
                                                    )}
                                                  </div>
                                                </Col>
                                                <Col
                                                  md={6}
                                                  className="account-col"
                                                >
                                                  <div
                                                    className="form__form-group"
                                                    style={{
                                                      marginBottom: '10px'
                                                    }}
                                                  >
                                                    <div className="form__form-group-field">
                                                      <input
                                                        type="tel"
                                                        name="cvc"
                                                        placeholder="CVC"
                                                        minLength={3}
                                                        maxLength={4}
                                                        className="bg-white"
                                                        {...register('cvc')}
                                                        value={formData.cvc}
                                                        onChange={
                                                          handleInputChange
                                                        }
                                                        onFocus={(e) =>
                                                          setFocused(
                                                            e.target.name
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    {errors.cvc && (
                                                      <p className="error">
                                                        {errors.cvc.message}
                                                      </p>
                                                    )}
                                                  </div>
                                                </Col>
                                                <Col
                                                  xl={12}
                                                  lg={12}
                                                  md={12}
                                                  className="mt-4"
                                                >
                                                  <div className="form__form-group">
                                                    <div className="form__form-group-field custom-checkbox">
                                                      <CheckBox
                                                        name="remember_me"
                                                        value={
                                                          isToggleCheckboxEnabled
                                                        }
                                                        onChange={
                                                          handleToggleCheckBox
                                                        }
                                                      />
                                                      Salvar esse cartão para
                                                      futuras compras
                                                    </div>
                                                  </div>
                                                </Col>
                                              </form>
                                            </Col>
                                          </Row>
                                        )}
                                      </>
                                    )}
                                  </Row>
                                </Label>
                              </Col>
                            ))}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12}>
                            <button
                              type="button"
                              onClick={() => onSubmit()}
                              disabled={!selectedOption}
                              className="btn btn-primary mt-3"
                            >
                              Pagar
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default CreditCardPayment;
