import React, { useState } from 'react';
import { Col } from 'reactstrap';
import axios from 'axios';
import { getToken } from '../../../services/auth';
import CarouselMultiply from '../../../shared/components/carousel/CarouselMultiply';

const Pricing = () => {
  const [disabledButton, setDisabledButton] = useState(false);
  const buyPackage = async (pack) => {
    setDisabledButton(true);
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/preSale`;
    let link = '';
    switch (pack) {
      case 'pro plus':
        link = '7YfUqRRba';
        break;
      case 'profissional':
        link = '7YfUqFLev';
        break;
      case 'avancado':
        link = '7YfUqvbmb';
        break;
      case 'intermediario':
        link = '7YfUpzHHR';
        break;
      case 'basico':
        link = '7YfUM7NnG';
        break;
      case 'inicial':
        link = '7YhAH5X7G';
        break;
      default:
        break;
    }
    await axios.post(
      apiUrl,
      {
        package: pack
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    );
    await window.open(`https://pag.ae/${link}`, '_blank');
    setDisabledButton(false);
  };

  return (
    <Col md={12} style={{ padding: '0 60px' }}>
      <div className="pricing">
        <CarouselMultiply>
          <div className="cards" key={`pricing-${1}`}>
            <div className="card-pricing">
              <ul className="inside">
                <li className="pack">Inicial</li>
                <li id="basic" className="price">
                  R$2,50
                </li>
                <li className="bottom-bar pt-0">cada requisição</li>
                <li className="bottom-bar">1.000 requisições</li>
                <li className="bottom-bar">NFCE, NFE e CFE</li>
                <li className="bottom-bar">Suporte via ticket 24/7</li>
                <li>
                  <button
                    className="btn"
                    type="button"
                    onClick={() => buyPackage('inicial')}
                    disabled={disabledButton}
                  >
                    Comprar
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="cards" key={`pricing-${2}`}>
            <div className="card-pricing">
              <ul className="inside">
                <li className="pack">Basico</li>
                <li id="basic" className="price">
                  R$1,50
                </li>
                <li className="bottom-bar pt-0">cada requisição</li>
                <li className="bottom-bar">10.000 requisições</li>
                <li className="bottom-bar">NFCE, NFE e CFE</li>
                <li className="bottom-bar">Suporte via ticket 24/7</li>
                <li>
                  <button
                    className="btn"
                    type="button"
                    onClick={() => buyPackage('basico')}
                    disabled={disabledButton}
                  >
                    Comprar
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="cards" key={`pricing-${3}`}>
            <div className="card-pricing">
              <ul className="inside">
                <li className="pack">Intermediário</li>
                <li id="basic" className="price">
                  R$0,80
                </li>
                <li className="bottom-bar pt-0">cada requisição</li>
                <li className="bottom-bar">50.000 requisições</li>
                <li className="bottom-bar">NFCE, NFE e CFE</li>
                <li className="bottom-bar">Suporte via ticket 24/7</li>
                <li>
                  <button
                    className="btn"
                    type="button"
                    onClick={() => buyPackage('basico')}
                    disabled={disabledButton}
                  >
                    Comprar
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="cards" key={`pricing-${4}`}>
            <div className="card-pricing active">
              <ul className="inside">
                <li className="pack">Avançado</li>
                <li id="professional" className="price">
                  R$0,40
                </li>
                <li className="bottom-bar pt-0">cada requisição</li>
                <li className="bottom-bar">200.000 requisições</li>
                <li className="bottom-bar">NFCE, NFE e CFE</li>
                <li className="bottom-bar">Suporte via ticket 24/7</li>
                <li>
                  <button
                    className="btn active-btn"
                    type="button"
                    onClick={() => buyPackage('avancado')}
                    disabled={disabledButton}
                  >
                    Comprar
                  </button>
                </li>
              </ul>
              <span className="recommend">Recomendado</span>
            </div>
          </div>
          <div className="cards" key={`pricing-${5}`}>
            <div className="card-pricing">
              <ul className="inside">
                <li className="pack">Profissional</li>
                <li id="master" className="price">
                  R$0,25
                </li>
                <li className="bottom-bar pt-0">cada requisição</li>
                <li className="bottom-bar">400.000 requisições</li>
                <li className="bottom-bar">NFCE, NFE e CFE</li>
                <li className="bottom-bar">Suporte via ticket 24/7</li>
                <li>
                  <button
                    className="btn"
                    type="button"
                    onClick={() => buyPackage('profissional')}
                    disabled={disabledButton}
                  >
                    Comprar
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="cards" key={`pricing-${6}`}>
            <div className="card-pricing">
              <ul className="inside">
                <li className="pack">Pro Plus</li>
                <li id="basic" className="price">
                  R$0,15
                </li>
                <li className="bottom-bar pt-0">cada requisição</li>
                <li className="bottom-bar">1.000.000 requisições</li>
                <li className="bottom-bar">NFCE, NFE e CFE</li>
                <li className="bottom-bar">Infraestrutura exclusiva</li>
                <li>
                  <button
                    className="btn"
                    type="button"
                    onClick={() => buyPackage('pro plus')}
                    disabled={disabledButton}
                  >
                    Comprar
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </CarouselMultiply>
      </div>
    </Col>
  );
};

export default Pricing;
