import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Col, Container, Row } from 'reactstrap';
import Alert from '../../shared/Alert';
import Information from './components/Information';

const ExamplePage = () => {
  const style = {
    textTransform: 'capitalize',
    /* font-size: 20px; */
    color: '#2A2E4F',
    fontWeight: 600,
    alignItems: 'center',
    marginTop: '20px'
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '64px' }}>
        <Col md={6} col={12} >
          <h3 style={style}>Pacotes</h3>
        </Col>
        <Col md={6} col={12}>
          <Alert color="info" className="alert--bordered" icon>
            <p>
              <span className="bold-text">Atenção!</span> Favor utilizar o mesmo
              <span className="bold-text"> email de cadastro</span> na hora do pagamento, para maior rapidez na
              liberação do número de requisições.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Information />
      </Row>
    </Container>
  );
};

export default ExamplePage;
