import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';

const ModalComponent = ({ color, title, message, colored, header, toggleFunction }) => {
  const [modal, setModal] = useState(true);

  const toggle = () => {
    setModal((prevState) => !prevState);
    if (toggleFunction) toggleFunction();
  };

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header
  });

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        modalClassName="ltr-support"
        className={`modal-dialog--${color} ${modalClass}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          {header ? '' : Icon}
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body">{message}</div>
        <ButtonToolbar className="modal__footer">
          <Button
            className="modal_ok"
            outline={colored}
            color={color}
            onClick={toggle}
          >
            Ok
          </Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  toggleFunction: PropTypes.func,
};

ModalComponent.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
  toggleFunction: undefined,
};

export default ModalComponent;
