import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

const ProfileMain = () => {
  const { name, email, logo } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const Ava = logo || `${process.env.PUBLIC_URL}/img/user_avatar.png`;

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__avatar">
            <img src={Ava} alt="avatar" />
          </div>
          <div className="profile__information">
            <div className="profile__data">
              <p className="profile__name">{name}</p>
              <p className="profile__work">{email}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileMain;
