/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import Cards from 'react-credit-cards-2';
import { Card, Col, Container, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import axios from 'axios';
import { PuffLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import { getToken } from '../../services/auth';

const schema = yup.object().shape({
  number: yup
    .string()
    .required('Número do cartão é obrigatório')
    .matches(/^[0-9\s]{19}$/, 'O número do cartão deve ter 16 dígitos'),
  name: yup
    .string()
    .required('Nome é obrigatório')
    .min(3, 'O nome deve ter pelo menos 3 caracteres'),
  expiry: yup
    .string()
    .required('Validade é obrigatória')
    .matches(
      /^(0[1-9]|1[0-2])\/([0-9]{4})$/,
      'A validade deve estar no formato MM/YY'
    ),
  cvc: yup
    .string()
    .required('CVC é obrigatório')
    .min(3, 'O CVC deve ter no mínimo 3 dígitos')
    .max(4, 'O CVC deve ter no máximo 4 dígitos')
});

const CreditCards = () => {
  const client = JSON.parse(localStorage.getItem('client') || '""');

  const [focused, setFocused] = useState('');
  const [loading, setLoading] = useState(false);
  const [color] = useState('#00AFEF');
  const [cards, setCards] = useState([]);
  const [formData, setFormData] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: ''
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  const getCards = useCallback(async () => {
    try {
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_WAS_KEY}/wallet/credit-card`;
      const res = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'x-api-key': client.owner
        }
      });
      setCards(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }, [client.owner]);

  useEffect(() => {
    getCards();
  }, [getCards]);

  const hashCreditCard = (galaxPay, card) =>
    new Promise((resolve, reject) => {
      galaxPay.hashCreditCard(
        card,
        (hash) => resolve(hash),
        (error) => reject(error)
      );
    });

  const handleAddCard = async () => {
    const token = process.env.REACT_APP_GALAX_PAY;
    const env = process.env.REACT_APP_ENV;
    const galaxPay = new GalaxPay(token, env === 'production');
    try {
      setLoading(true);
      const card = await galaxPay.newCard({
        number: formData.number,
        holder: formData.name,
        expiresAt: formData.expiry.split('/').reverse().join('-'),
        cvv: formData.cvc
      });
      const hash = await hashCreditCard(galaxPay, card);
      const apiUrl = `${process.env.REACT_APP_WAS_KEY}/wallet/credit-card`;

      await axios.post(
        apiUrl,
        {
          creditCardHash: hash
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': client.owner
          }
        }
      );
      toast.success(`Cartão adicionado com sucesso.`, {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: false,
        hideProgressBar: true,
        theme: 'colored'
      });

      reset();
      setFormData({ number: '', name: '', expiry: '', cvc: '' });
      await getCards();
    } catch (error) {
      toast.error(`Ocorreu um erro ao adicionar o cartão.`, {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: false,
        hideProgressBar: true,
        theme: 'colored'
      });
      throw error.message;
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleRemoveCard = async (creditCardId) => {
    try {
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_WAS_KEY}/wallet/credit-card/${creditCardId}`;

      await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'x-api-key': client.owner
        }
      });
      toast.success(`Cartão removido com sucesso.`, {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: false,
        hideProgressBar: true,
        theme: 'colored'
      });

      reset();
      setFormData({ number: '', name: '', expiry: '', cvc: '' });
      await getCards();
    } catch (error) {
      toast.error(`Ocorreu um erro ao remover o cartão.`, {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: false,
        hideProgressBar: true,
        theme: 'colored'
      });
      throw error.message;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Container className="dashboard">
        <Row style={{ marginBottom: '10px' }}>
          <Col xs={12} md={12} xl={10}>
            <h3 className="page-title" style={{ textTransform: 'none' }}>
              Cartão de crédito
            </h3>
          </Col>
        </Row>
        <Card className="payment-container">
          {loading ? (
            <Row>
              <Col
                xl={12}
                className="d-flex text-center align-items-center justify-content-center my-4"
              >
                <div className="sweet-loading" style={{ height: '500px' }}>
                  <PuffLoader
                    color={color}
                    loading={loading}
                    size={100}
                    speedMultiplier={1.5}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <>
              <h3 className="page-title">Adicionar Cartão</h3>
              <Row>
                <Col
                  xl={{ size: 3, offset: 3 }}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mt-3"
                >
                  <Cards
                    number={formData.number.replace(/\s/g, '')}
                    name={formData.name}
                    expiry={formData.expiry}
                    cvc={formData.cvc}
                    focused={focused}
                    locale={{ valid: 'Validade' }}
                    placeholders={{ name: 'Nome do cartão' }}
                  />
                </Col>
                <Col
                  xl={3}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="mt-3"
                >
                  <form className="form" onSubmit={handleSubmit(handleAddCard)}>
                    <Col md={12} className="account-col">
                      <div
                        className="form__form-group"
                        style={{ marginBottom: '10px' }}
                      >
                        <div className="form__form-group-field">
                          <InputMask
                            mask="9999 9999 9999 9999"
                            value={formData.number}
                            onChange={(e) => {
                              handleInputChange(e);
                              setValue('number', e.target.value); // Atualiza valor no react-hook-form
                            }}
                            onFocus={(e) => setFocused(e.target.name)}
                          >
                            {() => (
                              <input
                                type="tel"
                                name="number"
                                placeholder="Número do Cartão"
                              />
                            )}
                          </InputMask>
                        </div>
                        {errors.number && (
                          <p className="error">{errors.number.message}</p>
                        )}
                      </div>
                    </Col>
                    <Col md={12} className="account-col">
                      <div
                        className="form__form-group"
                        style={{ marginBottom: '10px' }}
                      >
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            name="name"
                            placeholder="Nome no Cartão"
                            {...register('name')}
                            value={formData.name}
                            onChange={handleInputChange}
                            onFocus={(e) => setFocused(e.target.name)}
                          />
                        </div>
                        {errors.name && (
                          <p className="error">{errors.name.message}</p>
                        )}
                      </div>
                    </Col>
                    <Col md={6} className="account-col">
                      <div
                        className="form__form-group"
                        style={{ marginBottom: '10px' }}
                      >
                        <div className="form__form-group-field">
                          <InputMask
                            mask="99/9999"
                            value={formData.expiry}
                            onChange={(e) => {
                              handleInputChange(e);
                              setValue('expiry', e.target.value); // Atualiza valor no react-hook-form
                            }}
                            onFocus={(e) => setFocused(e.target.name)}
                          >
                            {() => (
                              <input
                                type="tel"
                                name="expiry"
                                placeholder="Validade (MM/YY)"
                              />
                            )}
                          </InputMask>
                        </div>
                        {errors.expiry && (
                          <p className="error">{errors.expiry.message}</p>
                        )}
                      </div>
                    </Col>
                    <Col md={6} className="account-col">
                      <div
                        className="form__form-group"
                        style={{ marginBottom: '10px' }}
                      >
                        <div className="form__form-group-field">
                          <input
                            type="tel"
                            name="cvc"
                            placeholder="CVC"
                            minLength={3}
                            maxLength={4}
                            {...register('cvc')}
                            value={formData.cvc}
                            onChange={handleInputChange}
                            onFocus={(e) => setFocused(e.target.name)}
                          />
                        </div>
                        {errors.cvc && (
                          <p className="error">{errors.cvc.message}</p>
                        )}
                      </div>
                    </Col>

                    <button
                      type="submit"
                      className="btn btn-primary account__btn account__btn--small"
                      style={{ margin: '0px 15px' }}
                    >
                      Adicionar Cartão
                    </button>
                  </form>
                </Col>
              </Row>
              {cards.length >= 1 && (
                <>
                  <hr />
                  <h3 className="page-title">Meus Cartões</h3>
                  <div className="saved-cards">
                    <Row>
                      {cards.map((card) => (
                        <Col
                          xl="4"
                          lg="6"
                          md="12"
                          sm="12"
                          xs="12"
                          style={{ marginTop: '30px', display: 'flex' }}
                        >
                          <Cards
                            number={card.number}
                            expiry={card.expiresAt
                              .split('-')
                              .reverse()
                              .join('/')}
                            locale={{ valid: 'Validade' }}
                            placeholders={{ name: '' }}
                          />
                          <span
                            className="sidebar__link-icon lnr lnr-trash delete-credit"
                            onClick={() => handleRemoveCard(card.internalId)}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default CreditCards;
