/* eslint-disable */
import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import PuffLoader from 'react-spinners/PuffLoader';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import Modal from '../../../shared/Modal';

const RecoverPasswordForm = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openExpiredModal, setOpenExpiredModal] = useState(false);
  const [color] = useState('#00AFEF');
  const history = useHistory();
  const token = useRef('');

  React.useEffect(() => {
    token.current = props.location.search.replace("?token=", "");
    if (!token.current) history.push('/login');
    checkToken();
    
    const subscription = watch((value, { name }) => {
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const handleShowConfirmPassword = () => {
    setIsConfirmPasswordShown(!isConfirmPasswordShown);
  };

  const backToLogin = () => {
    history.push('/login');
  }

  const checkToken = () => {
    const decodedToken = jwtDecode(token.current);
    const dateNow = new Date();

    if ((decodedToken.exp * 1000) < dateNow.getTime()) {
      setTimeout(() => {
        setOpenExpiredModal(true);
      }, 500);    
    }
  }

  const onSubmit = (data) => {
    setLoading(true);
    props.onSubmit(true);
    const dataForm = data;
    delete data.confirmPassword;
    checkToken();
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/recoverPassword`;
    const config = {
      headers: { Authorization: `Bearer ${token.current}` }
    };
    axios
      .post(apiUrl, dataForm, config)
      .then((res) => {
        if (res.data.created === false) throw new Error('Erro Interno');
        setOpen(true);
      })
      .catch(() => {
        setOpenError(true);
        setLoading(false);
        props.onSubmit(false);
      });
  };

  return loading ? (
    <div className="sweet-loading">
      <PuffLoader
        color={color}
        loading={loading}
        size={100}
        speedMultiplier={1.5}
      />
      {open ? (
        <Modal
          color="primary"
          title="Sucesso!"
          colored
          message="Senha alterada com suceso."
          isOpened={open}
          toggleFunction={backToLogin}  
        />
      ) : null}
    </div>
  ) : (
    <>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Senha</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input
              name="password"
              type={isPasswordShown ? 'text' : 'password'}
              placeholder=""
              {...register('password', {
                required: 'Este campo é obrigatório.'
              })}
            />
            <button
              className={`form__form-group-button${
                isPasswordShown ? ' active' : ''
              }`}
              onClick={() => handleShowPassword()}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          {errors.password && (
            <span className="error">{errors.password.message}</span>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Confirme a senha</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input
              name="confirmPassword"
              type={isConfirmPasswordShown ? 'text' : 'password'}
              placeholder=""
              {...register('confirmPassword', {
                required: 'Este campo é obrigatório.',
                validate: (value) => value === watch("password") || "Senhas não são iguais"
              })}
            />
            <button
              className={`form__form-group-button${
                isConfirmPasswordShown ? ' active' : ''
              }`}
              onClick={() => handleShowConfirmPassword()}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          {errors.confirmPassword && (
            <span className="error">{errors.confirmPassword.message}</span>
          )}
        </div>
        <button
          className="btn btn-primary account__btn account__btn--small"
          type="submit"
        >
          Confirmar
        </button>
        <span
          className="form__form-group-description"
          style={{ fontWeight: 600 }}
        >
          <a href="/">Ir para o Login</a>
        </span>
      </form>
      {openError ? (
        <Modal
          color="danger"
          title="Erro!"
          colored
          message="Ocorreu um erro interno, por favor aguarde um momento ou tente novamente mais tarde."
          isOpened={openError}
        />
      ) : null}
      {openExpiredModal ? (
        <Modal
          color="danger"
          title="Erro!"
          colored
          message="Token expirado, por favor gere uma nova solicitação."
          isOpened={openExpiredModal}
          toggleFunction={backToLogin}  
        />
      ) : null}
    </>
  );
};

RecoverPasswordForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onSubmit: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default withRouter(RecoverPasswordForm);
