import React, { useEffect, useState } from 'react';
import { FaCopy, FaBarcode } from 'react-icons/fa';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Container, Row } from 'reactstrap';

const icon = `${process.env.PUBLIC_URL}/img/invoice.png`;

const BankSlip = () => {
  const location = useLocation();
  const [archive, setArchive] = useState('');
  const [barcode, setBarcode] = useState('');

  const openPdfInNewTab = () => {
    window.open(archive, '_blank', 'noopener,noreferrer');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(barcode);
  };

  useEffect(() => {
    if (location.state) {
      const { pdf, bankLine } = location.state;
      setArchive(pdf);
      setBarcode(bankLine);
    }
  }, [location.state]);

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '20px' }}>
        <Col xs={12} md={12} xl={12}>
          <h3 className="page-title">Boleto Bancário</h3>
        </Col>
      </Row>
      <div className="payment-container">
        <Row>
          <Col md={12} lg={12} xl={12}>
            <h2>Pagamento Pendente</h2>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <p className="payment-info">
              Para concluir sua recarga, realize o pagamento através do boleto
              bancário abaixo. Assim que o pagamento for confirmado, seus
              créditos serão liberados automaticamente.
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            md={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-center mt-4"
          >
            <img src={icon} alt="logo" className="payment-image" />
          </Col>
          <Col md={12} lg={12} xl={12} className="py-2">
            <div className="barcode-section">
              <p>
                <strong>Código de barras:</strong> {barcode}
              </p>
            </div>
          </Col>
          <Col
            md={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-center"
          >
            <button
              className="copy-button"
              onClick={copyToClipboard}
              type="button"
            >
              <FaCopy /> Copiar Código
            </button>
          </Col>
        </Row>

        <Row>
          <Col
            md={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-center"
          >
            <div className="actions">
              <button
                className="view-boleto-button"
                type="button"
                onClick={openPdfInNewTab}
              >
                <FaBarcode /> Ver Boleto
              </button>
            </div>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <div className="extra-info">
              <p>
                <strong>Importante:</strong> o boleto pode levar até 2 dias
                úteis para ser processado. Verifique se todos os dados estão
                corretos antes de realizar o pagamento.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default BankSlip;
