import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from '../../shared/components/Loading';
import LoadingService from '../../services/loading';

const MainWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const isLoading = LoadingService.getLoading();
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <div className="theme-light">
      <div className="wrapper">
        {loading ? <Loading /> : null}
        {children}
      </div>
    </div>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.element.isRequired
};

export default MainWrapper;
