/* eslint-disable dot-notation */

import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Col, Container, Row } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format, sub } from 'date-fns';
import NotesGraph from '../Home/components/NotesGraph';
import StatesPie from '../Home/components/StatesPie';
import StatusPie from '../Home/components/StatusPie';
import { normalizeName, ShoppingCode } from '../../shared/utils/utils';

registerLocale('ptBR', ptBR);

const Shoppings = () => {
  const [dateRange, setDateRange] = useState([
    sub(new Date(), { weeks: 1 }),
    new Date()
  ]);
  const [startDate, endDate] = dateRange;
  const [selected, setSelect] = useState('ALL');
  const [inicialDate, setInicialDate] = useState(
    format(sub(new Date(), { weeks: 1 }), 'dd/MM/yyyy')
  );
  const [finalDate, setFinalDate] = useState(format(new Date(), 'dd/MM/yyyy'));
  const onChange = (date) => {
    setDateRange(date);
    if (date[0] && date[1]) {
      setInicialDate(format(new Date(date[0]), 'dd/MM/yyyy'));
      setFinalDate(format(new Date(date[1]), 'dd/MM/yyyy'));
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '30px' }}>
        <Col xs={12} md={12} xl={7}>
          <h3 className="page-title">Shoppings</h3>
        </Col>
        <Col xs={12} md={12} xl={2} className="p-0 text-center">
          <div className="form">
            <div className="form__form-group m-0">
              <div className="form__form-group-field">
                <select
                  className="select-form custom-select"
                  onChange={(e) => setSelect(e.target.value)}
                  value={selected}
                >
                  <option value="ALL">Todos</option>{
                    Object.keys(ShoppingCode).map((key) => 
                      <option value={key}>{normalizeName(ShoppingCode[key])}</option>
                    )
                  }
                </select>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={12} xl={3} className="p-0 text-center">
          <DatePicker
            selectsRange
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              onChange(update);
            }}
            dateFormat="dd/MM/yyyy"
            locale="ptBR"
            maxDate={new Date()}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <NotesGraph
            inicialDate={inicialDate}
            finalDate={finalDate}
            shopping={selected}
            url="checkOwnerRequestByMonthShopping"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <StatusPie
            inicialDate={inicialDate}
            finalDate={finalDate}
            shopping={selected}
            url="getCountNotasAllStatusShopping"
          />
        </Col>
        <Col md={6}>
          <StatesPie
            inicialDate={inicialDate}
            finalDate={finalDate}
            shopping={selected}
            url="getCountNotasAllStateShopping"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Shoppings;
